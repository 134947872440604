import React from 'react';
import { replace } from 'lodash';
import classNames from 'classnames';
import { r_heart, s_heart } from '@fiverr-private/icons';
import { grey_500 } from '@fiverr-private/sass/helpers';
import { Icon } from '@fiverr-private/fit';
import { translate, hasTranslation } from '@fiverr-private/i18n-react';
import { ELEMENTS_NAME, ELEMENTS_TYPE, URL_TARGET } from '../../Components/constants';
import { DEFAULT_CARD_CLASS, SELLER_FOCUSED_GIG_CARD } from '../../../GigCardListings/utils/constants';
import { createClickedElement, trackGigForClickType } from '../../utils';
import utilStyles from '../../styles/utils.module.scss';
import { AVATAR_ONLINE_ID_SIZE, COLLECT_ICON_SIZE, SM_AVATAR_SIZE } from './constants';
import LoadingGigCard from './components/LoadingGigCard';

import styles from './index.module.scss';

export const getGigWrapperProps = (props, enrichedTracker) => {
    const { uId, isPro, isUnavailable, isLoading, ExternalWrapper, isNonExperiential } = props;

    return {
        uId,
        isPro,
        isUnavailable,
        isLoading,
        ExternalWrapper,
        enrichedTracker,
        isNonExperiential,
        gig: props,
        classes: ['seller-focused', styles.sellerFocusedGigCard],
        type: SELLER_FOCUSED_GIG_CARD,
        layoutClass: DEFAULT_CARD_CLASS,
        LoadingComponent: LoadingGigCard,
    };
};

export const getContextualLinkProps = (props, trackEvent) => {
    const { url } = props;
    const clickedElement = createClickedElement(ELEMENTS_NAME.WRAPPER, ELEMENTS_TYPE.LINK);
    const onMouseDown = (e) => trackGigForClickType(e.button, trackEvent, clickedElement);

    return { href: url, target: URL_TARGET.blank, className: styles.linkWrapper, onMouseDown };
};

export const getSellerDetailsProps = (props) => {
    const { seller = {}, badge, showCountryFlag, isPromotedGig, sellerInNewTab } = props;
    const urlTarget = sellerInNewTab ? URL_TARGET.blank : URL_TARGET.self;

    return { ...seller, urlTarget, badge, showCountryFlag, isPromotedGig };
};

export const getSellerSpeaksProps = (props) => {
    const { showISpeak, seller: { sellerLanguages = [] } = {} } = props;

    return { showISpeak, sellerLanguages };
};

export const getGigTitleProps = (props) => {
    const { title, showTranslatedUGC, translatedTitle, localizedTitle, url } = props;

    return {
        hidePrefix: true,
        title,
        showTranslatedUGC,
        translatedTitle,
        localizedTitle,
        url,
        urlTarget: URL_TARGET.blank,
        className: classNames('p-0', styles.gigTitle),
    };
};

export const getPriceProps = (props) => {
    const { price, url, pricingFactor = {} } = props;

    return {
        price,
        url,
        urlTarget: URL_TARGET.blank,
        pricingFactorAlias: pricingFactor?.alias,
        className: classNames('m-t-0', styles.price, utilStyles.widthFitContent),
    };
};

export const getGigAttributesProps = (props) => {
    const { gigAttributes, url } = props;

    return {
        gigAttributes,
        url,
        urlTarget: URL_TARGET.blank,
    };
};

export const getCollectWrapperProps = (props) => {
    const { id, collectProps = {}, triggerData = {} } = props;
    const collectedIcon = (
        <Icon className={styles.filledIcon} size={COLLECT_ICON_SIZE}>
            {s_heart}
        </Icon>
    );
    const notCollectedIcon = (
        <Icon color={grey_500} size={COLLECT_ICON_SIZE}>
            {r_heart}
        </Icon>
    );

    return {
        id,
        collectProps,
        className: styles.collectWrapper,
        collectedClassName: styles.collected,
        collectedIcon,
        notCollectedIcon,
        triggerData,
    };
};

export const getServiceNameProps = (props) => {
    const { subCategoryId, nestedSubCategoryId, url } = props;
    let key = `sub_categories.sub_category_${subCategoryId}`;

    if (nestedSubCategoryId) {
        key = `${key}.nested_sub_cat_${nestedSubCategoryId}.name`;
    } else if (subCategoryId) {
        key = `${key}.name`;
    }

    if (hasTranslation(key)) {
        return {
            serviceName: translate(key),
            url,
            urlTarget: URL_TARGET.blank,
        };
    }
};

export const getAvatarProps = (props) => {
    const { seller: { isOnline, name, imgSrc = '' } = {} } = props;

    return {
        size: SM_AVATAR_SIZE,
        showOnlineIndicator: true,
        username: name,
        className: styles.avatar,
        src: replace(imgSrc, 't_profile_thumb', 't_profile_original'),
        isOnline,
        onlineIndicatorSize: AVATAR_ONLINE_ID_SIZE,
    };
};
