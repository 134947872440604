import React from 'react';
import { bool, object, string } from 'prop-types';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { Tooltip } from '@fiverr-private/tooltip';
import {
    FiverrChoiceBadge,
    ProBadge,
    SellerLevelOneBadge,
    SellerLevelTwoBadge,
    TopRatedBadge,
    BuyItAgainBadge,
} from '@fiverr-private/badges';
import { I18n } from '@fiverr-private/i18n-react';
import { GIG_FEATURE_BADGE_TYPES, SELLER_LEVELS } from '../../../../GigCardListings/utils/gigBadges/constants';
import { isInExperimentGroup } from '../../../../GigCardListings/ListingsTransformer/abTestsTransformer';
import { ALLOCATIONS, EXPERIMENTS, ROLLOUTS } from '../../../../constants/experiments';
import { useRolloutsContext } from '../../../../GigCardListings/context/RolloutsContext/RolloutsContext';
import { GIG_CARD_TYPES, SELLER_LEVEL_TYPES } from './constants';
import { getTranslationTemplates } from './utils';

import styles from './index.module.scss';

const MAP_TYPE_TO_BADGE = {
    [GIG_FEATURE_BADGE_TYPES.PRO]: ProBadge,
    [GIG_FEATURE_BADGE_TYPES.FIVERR_CHOICE]: FiverrChoiceBadge,
    [GIG_FEATURE_BADGE_TYPES.BUY_IT_AGAIN]: BuyItAgainBadge,
};

const MAP_SELLER_LEVEL_TO_BADGE = {
    [SELLER_LEVELS.TOP_RATED_SELLER]: TopRatedBadge,
    [SELLER_LEVELS.LEVEL_ONE_SELLER]: SellerLevelOneBadge,
    [SELLER_LEVELS.LEVEL_TWO_SELLER]: SellerLevelTwoBadge,
};

const Badge = ({
    type,
    level,
    excludeTooltip,
    className = '',
    wrapperClassName = '',
    shouldShowGigTypeBadge = true,
    isAgency = false,
}) => {
    const { rollouts } = useRolloutsContext();

    const { isTouch, abTests } = getContext();
    const hideTooltip = excludeTooltip || isTouch;
    const showTooltip = !hideTooltip;

    const showSellerOverlay = isInExperimentGroup(EXPERIMENTS.HAWKS_OVERLAY_BADGES, [ALLOCATIONS.TEST_B], abTests);
    const showNewSellerLevelBadges = rollouts[ROLLOUTS.QUALAS_NEW_LEVEL_SYSTEM_CONSUMERS_MIGRATION];

    if (showNewSellerLevelBadges || showSellerOverlay) {
        try {
            const TypeBadge = MAP_TYPE_TO_BADGE[type];
            const isProOrFiverrChoice = [GIG_FEATURE_BADGE_TYPES.PRO, GIG_FEATURE_BADGE_TYPES.FIVERR_CHOICE].includes(
                type
            );
            const showOverlayInsteadOfBadge = showSellerOverlay && isProOrFiverrChoice;
            const shouldShowTypeBadge = shouldShowGigTypeBadge && !showOverlayInsteadOfBadge && TypeBadge;

            if (shouldShowTypeBadge) {
                return (
                    <span style={{ flexShrink: 0 }}>
                        <TypeBadge showTooltip={showTooltip} appendTooltipToBody isAgency={isAgency} />
                    </span>
                );
            }

            const LevelBadge = MAP_SELLER_LEVEL_TO_BADGE[level];
            if (LevelBadge) {
                return (
                    <span style={{ flexShrink: 0 }}>
                        <LevelBadge showTooltip={showTooltip} appendTooltipToBody />
                    </span>
                );
            }

            return null;
        } catch (e) {
            return null;
        }
    }

    // Control group
    if (type === GIG_FEATURE_BADGE_TYPES.PRO) {
        return <ProBadge isAgency={isAgency} />;
    }

    const badgeProperties = GIG_CARD_TYPES[type] || SELLER_LEVEL_TYPES[level];

    if (!badgeProperties) {
        return null;
    }

    const { style, titleKey, titleTemplateKey, tooltipKey, Icon } = badgeProperties;

    const TooltipContent = !excludeTooltip ? (
        <div className={classNames('tbody-6', 'text-semi-bold', styles.tooltip)}>
            <I18n k={tooltipKey} />
        </div>
    ) : null;

    const BadgeComponent = () => (
        <div className={classNames('tbody-6', 'text-semi-bold', styles.badge, style, className)}>
            {Icon && <Icon size={12} />}
            <I18n k={titleKey} templates={getTranslationTemplates(titleTemplateKey)} />
        </div>
    );

    return (
        <div className={classNames(styles.tooltipWrapper, wrapperClassName)}>
            {isTouch ? (
                <BadgeComponent />
            ) : (
                <Tooltip appendToBody content={TooltipContent}>
                    <BadgeComponent />
                </Tooltip>
            )}
        </div>
    );
};

Badge.propTypes = {
    type: object,
    level: string,
    excludeTooltip: bool,
    className: string,
    wrapperClassName: string,
    shouldShowGigTypeBadge: bool,
    isAgency: bool,
};

export default Badge;
