import React from 'react';
import { bool, string } from 'prop-types';
import { business_800, white } from '@fiverr-private/sass/helpers';
import { translate } from '@fiverr-private/i18n-react';
import { CheckStarSolidIcon } from '@fiverr-private/icons';
import { PentaBadge as Badge } from '../PentaBadge/PentaBadge';

import { baseBadge, content } from './OverlayWrapper.module.scss';

export const ProOverlayBadge = ({
    showTooltip = true,
    tooltipPosition = 'top',
    dataTestId,
    appendTooltipToBody,
    disableTooltipFlip,
    isAgency = false,
}) => (
    <Badge
        className={baseBadge}
        showTooltip={showTooltip}
        color={white}
        backgroundColor={business_800}
        tooltipContent={isAgency ? translate('badges.pro_badge.agency_tooltip') : translate('badges.pro_badge.tooltip')}
        tooltipPosition={tooltipPosition}
        dataTestId={dataTestId}
        appendTooltipToBody={appendTooltipToBody}
        disableTooltipFlip={disableTooltipFlip}
    >
        <div className={content}>
            <CheckStarSolidIcon size={11} color="currentColor" />
            <p>Pro</p>
        </div>
    </Badge>
);

ProOverlayBadge.displayName = 'ProOverlayBadge';

ProOverlayBadge.propTypes = {
    showTooltip: bool,
    tooltipPosition: string,
    dataTestId: string,
    appendTooltipToBody: bool,
    disableTooltipFlip: bool,
    isAgency: bool,
};
