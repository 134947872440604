import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ControlledCheckbox, RadioGroup, Range, CheckboxGroup, PairSelect } from '../content/filters';
import SelectContainer from '../content/SelectContainer';
import SwitchContainer from '../../SwitchContainer';
import { DISPLAY_TYPES } from '../../../../config/filters';
import { FilterHeader } from './FilterHeader';
import { FILTERS_EXTRAS_PROPS, FILTERS_DISPLAY } from './consts';
import './index.scss';

const filtersComponentsMap = {
    [DISPLAY_TYPES.PRO]: SwitchContainer,
    [DISPLAY_TYPES.TOGGLE]: SwitchContainer,
    [DISPLAY_TYPES.TOGGLE_DETAILED]: SwitchContainer,
    [DISPLAY_TYPES.CHECKBOX]: ControlledCheckbox,
    [DISPLAY_TYPES.INPUT_RANGE]: Range,
    [DISPLAY_TYPES.RADIO]: RadioGroup,
    [DISPLAY_TYPES.CHECKBOX_GROUP]: CheckboxGroup,
    [DISPLAY_TYPES.AUTO_COMPLETE]: SelectContainer,
    [DISPLAY_TYPES.MULTI_PAIRS_SELECT]: PairSelect,
};

const inlineFilters = [DISPLAY_TYPES.TOGGLE, DISPLAY_TYPES.PRO, DISPLAY_TYPES.TOGGLE_DETAILED];

class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.clear = this.clear.bind(this);
    }

    clear(id, value = null) {
        const { onClear } = this.props;
        onClear(id, value);
    }

    render() {
        const { value, filter, additionalProps, onChange, setIsValidFilter } = this.props;
        const { showTitle = true } = additionalProps;
        const { display_type: filterType, id: filterId } = filter;
        const displayType = FILTERS_DISPLAY[filterId] ? FILTERS_DISPLAY[filterId] : filterType;

        const RenderedFilter = filtersComponentsMap[displayType];

        if (!RenderedFilter) {
            return null;
        }

        const extraProps = FILTERS_EXTRAS_PROPS[filterType] || {};
        const containerClassNames = classNames({
            'inline-content': inlineFilters.includes(displayType),
            'toggle-detailed': displayType === DISPLAY_TYPES.TOGGLE_DETAILED,
        });
        const mergedProps = {
            ...extraProps,
            ...additionalProps,
        };

        return (
            <div className={containerClassNames}>
                {showTitle && (
                    <FilterHeader
                        filter={filter}
                        displayType={displayType}
                        showClear={extraProps.showClear}
                        onClear={() => this.clear(filterId)}
                    />
                )}
                <RenderedFilter
                    value={value}
                    {...filter}
                    {...mergedProps}
                    onChange={onChange}
                    onClear={this.clear}
                    setIsValidFilter={setIsValidFilter}
                />
            </div>
        );
    }
}

Filter.propTypes = {
    value: PropTypes.any,
    filter: PropTypes.object.isRequired,
    additionalProps: PropTypes.object,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    setIsValidFilter: PropTypes.func,
};

Filter.defaultProps = {
    additionalProps: {},
    onChange: () => true,
    onClear: () => true,
};

export default Filter;
