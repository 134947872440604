import SellerFocusedGigCard from '../../../GigCards/ModernGigCards/SellerFocusedGigCard';
import BasicGigCard from '../../../GigCards/ModernGigCards/BasicGigCard';
import GigCard from '../../../GigCards/GigCard';

export const renderGigByExperience = (isNonExperiential, isExperiential) => {
    if (isNonExperiential) {
        return SellerFocusedGigCard;
    }

    if (isExperiential) {
        return BasicGigCard;
    }

    return GigCard;
};
