import React from 'react';
import classNames from 'classnames';
import { bool, string } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { Typography } from '@fiverr-private/typography';
import { createClickedElement, trackGigForClickType } from '../../../utils';
import { ELEMENTS_NAME, ELEMENTS_TYPE } from '../../../Components/constants';
import { useCardContext } from '../../../../GigCardListings/context/CardContext';
import ContextualLink from '../../../Components/ContextualLink';

import { sellerNamePrefixStyle, sellerNameWrapperStyle } from './SellerName.ve.css';

const clickedElement = (title) => createClickedElement(ELEMENTS_NAME.SELLER_TITLE, ELEMENTS_TYPE.LINK, title);

export const SellerName = ({ username, displayName, url, urlTarget, isPromotedGig }) => {
    const {
        enrichedTracker: { trackEvent },
    } = useCardContext();
    const sellerTitle = displayName ? displayName : username;

    return (
        <span className={classNames('p-r-24', sellerNameWrapperStyle)}>
            {isPromotedGig && (
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>
                    <I18n k="gig_cards.badges.promoted_gigs.seller_name_prefix.ad_by" />
                    &nbsp;
                </span>
            )}
            <ContextualLink
                className="text-bold"
                href={url}
                rel="nofollow noopener noreferrer"
                target={urlTarget}
                onMouseDown={(e) => trackGigForClickType(e.button, trackEvent, clickedElement(sellerTitle))}
            >
                <Typography
                    color={{ hover: 'grey_1200', default: 'grey_1200' }}
                    decoration={{ hover: 'underline', default: 'none' }}
                    as="span"
                    title={sellerTitle}
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                >
                    {sellerTitle}
                </Typography>
            </ContextualLink>
        </span>
    );
};

SellerName.propTypes = {
    username: string,
    displayName: string,
    url: string,
    urlTarget: string,
    isPromotedGig: bool,
};
