import React, { useState, useEffect } from 'react';
import { GigCardType } from '../../types';
import { enrichGigTracker } from '../../utils';
import withTransformProps from '../../hoc/withTransformProps';
import { cardTransformer } from '../../utils/transformers';
import GigWrapper from '../../Components/GigWrapper';
import Media from '../../Components/Media';
import DeliveryAssetTooltip from '../../Components/Media/DeliveryAssetTooltip';
import GigRating from '../Components/GigRating';
import Price from '../Components/Price';
import CollectWrapper from '../Components/CollectWrapper';
import GigTitle from '../Components/GigTitle';
import SellerSpeaks from '../Components/SellerSpeaks';
import { getGigRatingProps } from '../utils';
import ConsultationIndication from './components/ConsultationIndication';
import SellerInfo from './components/SellerInfo';
import {
    getCollectWrapperProps,
    getGigTitleProps,
    getGigWrapperProps,
    getPriceProps,
    getSellerInfoProps,
    getSellerSpeaksProps,
    useGigPageLinkHoverState,
    useMediaProps,
} from './utils';

import './index.scss';

const BasicGigCard = (props) => {
    const [enrichedTracker, setEnrichedTracker] = useState({});

    useEffect(() => {
        setEnrichedTracker(enrichGigTracker(props.tracker, props));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const gigPageLinkHoverState = useGigPageLinkHoverState();
    const mediaProps = useMediaProps(props, gigPageLinkHoverState);
    const collectWrapperProps = getCollectWrapperProps(props);
    const sellerInfoProps = getSellerInfoProps(props);
    const gigWrapperProps = getGigWrapperProps(props, enrichedTracker);
    const gigTitleProps = getGigTitleProps(props, gigPageLinkHoverState);
    const sellerSpeaksProps = getSellerSpeaksProps(props);
    const ratingProps = getGigRatingProps(props);
    const priceProps = getPriceProps(props, gigPageLinkHoverState);
    const { offerConsultation } = props;

    return (
        <GigWrapper {...gigWrapperProps}>
            <div className="basic-gig-card">
                {mediaProps.showGalleryDecisionIndicators && mediaProps.showTooltip && (
                    <DeliveryAssetTooltip
                        showTooltip={mediaProps.showTooltip}
                        toggleTooltip={mediaProps.toggleTooltip}
                    />
                )}
                <Media {...mediaProps} />
                <CollectWrapper {...collectWrapperProps} />
                <SellerInfo {...sellerInfoProps} />
                <GigTitle {...gigTitleProps} />
                <SellerSpeaks {...sellerSpeaksProps} />
                <GigRating {...ratingProps} />
                <Price {...priceProps} />
                <ConsultationIndication shouldShow={offerConsultation} />
            </div>
        </GigWrapper>
    );
};

BasicGigCard.propTypes = GigCardType;

export default withTransformProps(cardTransformer)(BasicGigCard);
