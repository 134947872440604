import React, { useState } from 'react';
import classNames from 'classnames';
import { replace } from 'lodash';
import { BASIC_GIG_CARD, DEFAULT_CARD_CLASS } from '../../../GigCardListings/utils/constants';
import { TOOLTIP_MAPPER, URL_TARGET } from '../../Components/constants';
import gigTitleStyles from '../Components/GigTitle/index.module.scss';
import number from '../../../utils/formatting/number';
import LoadingBasicGigCard from '../loading/LoadingBasicGigCard';
import collectWrapperStyles from './components/CollectWrapper/index.module.scss';
/**
 * Hook that provides hover state for a gig page link.
 * @returns {Object} - An object containing the hover state and event handlers.
 * @property {boolean} isHovering - Indicates whether the gig page link is being hovered.
 * @property {function} onMouseEnter - Event handler for mouse enter event.
 * @property {function} onMouseLeave - Event handler for mouse leave event.
 */
export const useGigPageLinkHoverState = () => {
    const [isHovering, setIsHovering] = useState(false);
    const onMouseEnter = () => setIsHovering(true);
    const onMouseLeave = () => setIsHovering(false);

    return { isHovering, onMouseEnter, onMouseLeave };
};

/**
 * Hook that provides media props for a gig card.
 * @param {Object} props - The props for the gig card.
 * @param {string} props.url - The URL of the gig.
 * @param {string} props.badge - The badge of the gig.
 * @param {string} props.id - The ID of the gig.
 * @param {Array} props.gigAttachmentIds - The attachment IDs of the gig.
 * @param {string} props.urlTarget - The target of the gig URL.
 * @param {Object} props.assets - The assets of the gig.
 * @param {Object} props.asset - The asset of the gig.
 * @param {Object} props.localizedAssets - The localized assets of the gig.
 * @param {boolean} props.lazyLoad - Indicates whether lazy loading is enabled.
 * @param {Object} props.seller - The seller of the gig.
 * @param {boolean} props.isTouch - Indicates whether the device is touch-enabled.
 * @param {boolean} props.isUnavailable - Indicates whether the gig is unavailable.
 * @param {string} props.type - The type of the gig.
 * @param {boolean} props.showGalleryDecisionIndicators - Indicates whether to show gallery decision indicators.
 * @param {boolean} props.showTranslatedUGC - Indicates whether to show translated UGC.
 * @param {boolean} props.showNewImageRatio - Indicates whether to show new image ratio.
 * @param {Object} gigPageLinkHoverState - The hover state for the gig page link.
 * @param {boolean} gigPageLinkHoverState.isHovering - Indicates whether the gig page link is being hovered.
 * @param {function} gigPageLinkHoverState.onMouseEnter - Event handler for mouse enter event.
 * @param {function} gigPageLinkHoverState.onMouseLeave - Event handler for mouse leave event.
 * @returns {Object} - An object containing the media props for the gig card.
 * @property {string} url - The URL of the gig.
 * @property {string} badge - The badge of the gig.
 * @property {string} gigId - The ID of the gig.
 * @property {Array} gigAttachmentIds - The attachment IDs of the gig.
 * @property {string} urlTarget - The target of the gig URL.
 * @property {Object} seller - The seller of the gig.
 * @property {Object} assets - The assets of the gig.
 * @property {Object} asset - The asset of the gig.
 * @property {Object} localizedAssets - The localized assets of the gig.
 * @property {boolean} lazyLoad - Indicates whether lazy loading is enabled.
 * @property {boolean} isTouch - Indicates whether the device is touch-enabled.
 * @property {boolean} isUnavailable - Indicates whether the gig is unavailable.
 * @property {string} gigType - The type of the gig.
 * @property {boolean} showGalleryDecisionIndicators - Indicates whether to show gallery decision indicators.
 * @property {boolean} showTranslatedUGC - Indicates whether to show translated UGC.
 * @property {boolean} showNewImageRatio - Indicates whether to show new image ratio.
 * @property {boolean} showTooltip - Indicates whether to show the delivery asset tooltip.
 * @property {function} toggleTooltip - Function to toggle the delivery asset tooltip.
 * @property {boolean} playOnHover - Indicates whether to play the gig on hover.
 * @property {boolean} showPlayerControls - Indicates whether to show the gig player controls.
 * @property {boolean} showAudioRemainingTime - Indicates whether to show the remaining time for audio gigs.
 * @property {boolean} showSingleSlide - Indicates whether to show only a single slide for touch devices.
 * @property {function} onMouseEnter - Event handler for mouse enter event.
 * @property {function} onMouseLeave - Event handler for mouse leave event.
 */
export const useMediaProps = (props, gigPageLinkHoverState = {}) => {
    const {
        url,
        badge,
        id: gigId,
        gigAttachmentIds,
        urlTarget,
        assets,
        asset,
        localizedAssets,
        lazyLoad,
        seller,
        isTouch,
        isUnavailable,
        type: gigType,
        showGalleryDecisionIndicators,
        showTranslatedUGC,
        showNewImageRatio,
    } = props;

    const [showDeliveryAssetTooltip, setShowDeliveryAssetTooltip] = useState(false);

    const toggleDeliveryAssetTooltip = (tooltipType, value) => {
        if (tooltipType === TOOLTIP_MAPPER.DELIVERY_ASSET) {
            setShowDeliveryAssetTooltip(value);
        }
    };

    return {
        url,
        badge,
        gigId,
        gigAttachmentIds,
        urlTarget,
        seller,
        assets,
        asset,
        localizedAssets,
        lazyLoad,
        isTouch,
        isUnavailable,
        gigType,
        showGalleryDecisionIndicators,
        showTranslatedUGC,
        showNewImageRatio,
        showTooltip: showDeliveryAssetTooltip,
        toggleTooltip: toggleDeliveryAssetTooltip,
        playOnHover: true,
        showPlayerControls: true,
        showAudioRemainingTime: false,
        showSingleSlide: isTouch,
        onMouseEnter: gigPageLinkHoverState.onMouseEnter,
        onMouseLeave: gigPageLinkHoverState.onMouseLeave,
    };
};

/**
 * Retrieves the collect wrapper props.
 * @param {Object} props - The props for the collect wrapper.
 * @param {string} props.id - The ID of the gig.
 * @param {Object} props.collectProps - The collect props object.
 * @returns {Object} - An object containing the collect wrapper props.
 * @property {string} id - The ID of the gig.
 * @property {Object} collectProps - The collect props object.
 * @property {string} className - The class name for the collect wrapper.
 * @property {ReactNode} collectedIcon - The icon for the collected state.
 * @property {ReactNode} notCollectedIcon - The icon for the not collected state.
 */
export const getCollectWrapperProps = (props) => {
    const { id, collectProps = {}, triggerData = {} } = props;

    // eslint-disable-next-line react/prop-types
    const HeartButton = ({ className }) => <div className={classNames(collectWrapperStyles.collectBtn, className)} />;

    const collectedIcon = <HeartButton className={collectWrapperStyles.collected} />;
    const notCollectedIcon = <HeartButton />;

    return {
        id,
        collectProps,
        className: collectWrapperStyles.collectWrapper,
        collectedIcon,
        notCollectedIcon,
        triggerData,
    };
};

/**
 * Retrieves the seller information props.
 * @param {Object} props - The props for the seller information.
 * @param {Object} props.seller - The seller object.
 * @param {string} props.badge - The badge of the seller.
 * @param {boolean} props.showCountryFlag - Indicates whether to show the country flag.
 * @param {boolean} props.isPromotedGig - Indicates whether the gig is promoted.
 * @param {boolean} props.sellerInNewTab - Indicates whether the seller link should open in a new tab.
 * @param {boolean} props.hideSellerInfo - Indicates whether to hide the seller information.
 * @returns {Object} - An object containing the seller information props.
 * @property {Object} seller - The seller object.
 * @property {string} urlTarget - The target of the seller URL.
 * @property {string} badge - The badge of the seller.
 * @property {boolean} showCountryFlag - Indicates whether to show the country flag.
 * @property {boolean} isPromotedGig - Indicates whether the gig is promoted.
 * @property {boolean} hideSellerInfo - Indicates whether to hide the seller information.
 */
export const getSellerInfoProps = (props) => {
    const {
        seller = {},
        badge,
        showCountryFlag,
        isPromotedGig,
        sellerInNewTab,
        hideSellerInfo,
        excludedBadges = [],
    } = props;
    const urlTarget = sellerInNewTab ? URL_TARGET.blank : URL_TARGET.self;

    return { ...seller, urlTarget, badge, showCountryFlag, isPromotedGig, hideSellerInfo, excludedBadges };
};

/**
 * Retrieves the gig wrapper props.
 * @param {Object} props - The props for the gig wrapper.
 * @param {string} props.uId - The unique ID of the gig.
 * @param {boolean} props.isPro - Indicates whether the gig is a Pro gig.
 * @param {boolean} props.isUnavailable - Indicates whether the gig is unavailable.
 * @param {boolean} props.isLoading - Indicates whether the gig is loading.
 * @param {ReactNode} props.ExternalWrapper - The external wrapper component for the gig.
 * @param {boolean} props.isExperiential - Indicates whether the gig is experiential.
 * @param {Object} enrichedTracker - The enriched tracker object.
 * @returns {Object} - An object containing the gig wrapper props.
 * @property {string} uId - The unique ID of the gig.
 * @property {boolean} isPro - Indicates whether the gig is a Pro gig.
 * @property {boolean} isUnavailable - Indicates whether the gig is unavailable.
 * @property {boolean} isLoading - Indicates whether the gig is loading.
 * @property {ReactNode} ExternalWrapper - The external wrapper component for the gig.
 * @property {boolean} isExperiential - Indicates whether the gig is experiential.
 * @property {Object} enrichedTracker - The enriched tracker object.
 */
export const getGigWrapperProps = (props, enrichedTracker) => {
    const { uId, isPro, isUnavailable, isLoading, ExternalWrapper, isExperiential } = props;

    return {
        uId,
        isPro,
        isUnavailable,
        isLoading,
        ExternalWrapper,
        enrichedTracker,
        isExperiential,
        gig: props,
        type: BASIC_GIG_CARD,
        classes: ['basic-gig-card'],
        layoutClass: DEFAULT_CARD_CLASS,
        LoadingComponent: LoadingBasicGigCard,
    };
};

/**
 * Retrieves the gig title props.
 * @param {Object} props - The props for the gig title.
 * @param {Object} gigPageLinkHoverState - The hover state for the gig page link.
 * @param {boolean} gigPageLinkHoverState.isHovering - Indicates whether the gig page link is being hovered.
 * @returns {Object} - An object containing the gig title props.
 * @property {boolean} hidePrefix - Indicates whether to hide the prefix.
 * @property {string} title - The title of the gig.
 * @property {boolean} showTranslatedUGC - Indicates whether to show translated UGC.
 * @property {string} translatedTitle - The translated title of the gig.
 * @property {string} localizedTitle - The localized title of the gig.
 * @property {string} url - The URL of the gig.
 * @property {string} urlTarget - The target of the gig URL.
 * @property {string} className - The class name for the gig title.
 * @property {string} sellerType - The type of the seller.
 * @property {function} onMouseEnter - Event handler for mouse enter event.
 * @property {function} onMouseLeave - Event handler for mouse leave event.
 */
export const getGigTitleProps = (props, gigPageLinkHoverState = {}) => {
    const { hidePrefix, title, showTranslatedUGC, translatedTitle, localizedTitle, url, urlTarget, seller } = props;

    return {
        hidePrefix,
        title,
        showTranslatedUGC,
        translatedTitle,
        localizedTitle,
        url,
        urlTarget,
        className: classNames({ [gigTitleStyles.hovering]: gigPageLinkHoverState.isHovering }),
        sellerType: seller?.type,
        onMouseEnter: gigPageLinkHoverState.onMouseEnter,
        onMouseLeave: gigPageLinkHoverState.onMouseLeave,
    };
};

/**
 * Retrieves the seller speaks props.
 * @param {Object} props - The props for the seller speaks.
 * @param {boolean} props.showISpeak - Indicates whether to show the "I speak" section.
 * @param {Array} props.sellerLanguages - The languages spoken by the seller.
 * @param {string} props.sellerType - The type of the seller.
 * @returns {Object} - An object containing the seller speaks props.
 * @property {boolean} showISpeak - Indicates whether to show the "I speak" section.
 * @property {Array} sellerLanguages - The languages spoken by the seller.
 * @property {string} sellerType - The type of the seller.
 */
export const getSellerSpeaksProps = (props) => {
    const { showISpeak, seller: { sellerLanguages = [], type = 'freelancer' } = {} } = props;

    return { showISpeak, sellerLanguages, sellerType: type };
};

/**
 * Retrieves the price props.
 * @param {Object} props - The props for the price.
 * @param {Object} gigPageLinkHoverState - The hover state for the gig page link.
 * @param {boolean} gigPageLinkHoverState.isHovering - Indicates whether the gig page link is being hovered.
 * @returns {Object} - An object containing the price props.
 * @property {string} price - The price of the gig.
 * @property {string} url - The URL of the gig.
 * @property {string} urlTarget - The target of the gig URL.
 * @property {string} pricingFactorAlias - The alias of the pricing factor.
 * @property {function} onMouseEnter - Event handler for mouse enter event.
 * @property {function} onMouseLeave - Event handler for mouse leave event.
 */
export const getPriceProps = (props, gigPageLinkHoverState = {}) => {
    const { price, url, urlTarget, numericPricingFactor } = props;
    const { scope, alias = '' } = numericPricingFactor || {};
    const pricingFactorAlias = scope && replace(alias, scope, number(scope));

    return {
        price,
        url,
        urlTarget,
        pricingFactorAlias,
        onMouseEnter: gigPageLinkHoverState.onMouseEnter,
        onMouseLeave: gigPageLinkHoverState.onMouseLeave,
    };
};
