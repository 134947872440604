import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Collect from '@fiverr-private/collect';
import DeliveryAssetTooltip from '../Components/Media/DeliveryAssetTooltip';
import GigWrapper from '../Components/GigWrapper';
import SellerInfo from '../Components/SellerInfo';
import Media from '../Components/Media';
import Title from '../Components/Title';
import Price from '../Components/Price';
import Rating from '../Components/Rating';
import Badge from '../Components/Badge';
import LoadingGigCard from '../loading/LoadingGigCard';
import withTransformProps from '../hoc/withTransformProps';
import { cardTransformer } from '../utils/transformers';
import { ELEMENTS_TYPE, ELEMENTS_NAME } from '../Components/constants';
import { DEFAULT_CARD_CLASS, GIG_CARD } from '../../GigCardListings/utils/constants';
import { enrichGigTracker, gigClick, createClickedElement, collectElementsMapper } from '../utils';
import { GigCardType } from '../types';
import './index.scss';

class GigCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTooltips: {
                deliveryAsset: false,
            },
        };

        this.tooltipsToggler = this.tooltipsToggler.bind(this);
        this.getCardContent = this.getCardContent.bind(this);

        this.enrichedTracker = enrichGigTracker(props.tracker, props);
    }

    getChildContext() {
        const { id } = this.props;

        return { ...this.enrichedTracker, gigId: id };
    }

    getCardContent() {
        const {
            id,
            title,
            seller,
            url,
            urlTarget,
            ratings,
            badge,
            isPromotedGig,
            localizedTitle,
            translatedTitle,
            showTranslatedUGC,
            showISpeak,
            showCountryFlag,
        } = this.props;

        return (
            <>
                <SellerInfo
                    {...seller}
                    gigId={id}
                    gigUrl={url}
                    isPromoted={isPromotedGig}
                    showISpeak={showISpeak}
                    showCountryFlag={showCountryFlag}
                />
                <Title
                    title={title}
                    showTranslatedUGC={showTranslatedUGC}
                    localizedTitle={localizedTitle}
                    translatedTitle={translatedTitle}
                    url={url}
                    urlTarget={urlTarget}
                />
                <div className="content-info">
                    <Rating {...ratings} />
                    <Badge {...badge} />
                </div>
            </>
        );
    }

    tooltipsToggler(type, status) {
        this.setState({
            activeTooltips: {
                ...this.state.activeTooltips,
                [type]: status,
            },
        });
    }

    onCollectionClicked(props) {
        const { clickedComponent, isCollected } = props;
        if (isCollected) {
            return;
        }

        const collectionEventAttributes = collectElementsMapper[clickedComponent];
        const { elementName, elementType, elementText } = collectionEventAttributes;
        const { trackEvent } = this.enrichedTracker;
        gigClick(trackEvent, createClickedElement(elementName, elementType, elementText));
    }

    render() {
        const {
            uId,
            id,
            type,
            url,
            badge,
            price,
            priceLabelType,
            isPro,
            seller,
            assets,
            localizedAssets,
            isLoading,
            isUnavailable,
            isTouch,
            lazyLoad,
            urlTarget,
            showGalleryDecisionIndicators,
            collectProps,
            attachmentIds,
            ExternalWrapper,
            showTranslatedUGC,
            triggerData,
        } = this.props;
        const { activeTooltips } = this.state;

        return (
            <GigWrapper
                uId={uId}
                type={GIG_CARD}
                isPro={isPro}
                isUnavailable={isUnavailable}
                isLoading={isLoading}
                layoutClass={DEFAULT_CARD_CLASS}
                classes={['card']}
                ExternalWrapper={ExternalWrapper}
                LoadingComponent={LoadingGigCard}
                gig={this.props}
                enrichedTracker={this.enrichedTracker}
            >
                <>
                    {showGalleryDecisionIndicators && activeTooltips.deliveryAsset && (
                        <DeliveryAssetTooltip
                            showTooltip={activeTooltips.deliveryAsset}
                            toggleTooltip={this.tooltipsToggler}
                        />
                    )}
                    <Media
                        url={url}
                        badge={badge}
                        gigId={id}
                        seller={seller}
                        gigAttachmentIds={attachmentIds}
                        urlTarget={urlTarget}
                        assets={assets}
                        localizedAssets={localizedAssets}
                        lazyLoad={lazyLoad}
                        isTouch={isTouch}
                        isUnavailable={isUnavailable}
                        showTooltip={activeTooltips.deliveryAsset}
                        toggleTooltip={this.tooltipsToggler}
                        gigType={type}
                        showGalleryDecisionIndicators={showGalleryDecisionIndicators}
                        showTranslatedUGC={showTranslatedUGC}
                    />
                    {this.getCardContent()}
                    <footer>
                        <Collect
                            {...collectProps}
                            resource={{ id: id.toString(), type: Collect.RESOURCE_TYPES.GIG }}
                            onCollectionClickedCallback={(props) => this.onCollectionClicked(props)}
                            triggerData={triggerData}
                            allowMenuOverflow
                        />
                        <Price url={url} price={price} priceLabelType={priceLabelType} urlTarget={urlTarget} />
                    </footer>
                </>
            </GigWrapper>
        );
    }
}

GigCard.propTypes = GigCardType;

GigCard.childContextTypes = {
    gigId: PropTypes.number,
    trackEvent: PropTypes.func,
    trackImpression: PropTypes.func,
};

GigCard.defaultProps = {
    showGalleryDecisionIndicators: false,
    showTranslatedUGC: false,
};

export const clickedElement = (type) => createClickedElement(ELEMENTS_NAME.GIG_BADGE, ELEMENTS_TYPE.LINK, type);

export default compose(withTransformProps(cardTransformer))(GigCard);
