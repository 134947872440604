// Group by Team
export const ROLLOUTS = {
    // Hawks
    HAWKS_ALLOCATE_SELLER_LEVELS_BADGES: 'hawks_allocate_seller_levels_badges',
    HAWKS_SORT_BY_PRICE_POST_FREEZE: 'hawks_sort_by_price_post_freeze',
    QUALAS_NEW_LEVEL_SYSTEM_CONSUMERS_MIGRATION: 'qualas_new_level_system_consumers_migration',
    HAWKS_REDIRECT_DATA_FROM_BUILDER: 'hawks_redirect_data_from_builder',
    HAWKS_NEW_MIDDLEWARE_FLOW: 'hawks_new_middleware_flow',
    HAWKS_FETCH_GIGS_DATA_FROM_PHOENIX_PROMISE: 'hawks_fetch_gigs_data',
    HAWKS_FETCH_RELATED_SEARCH_TERMS_PROMISE: 'hawks_fetch_related_search_terms',
    HAWKS_PROFESSION_BASED_CACHE: 'hawks_profession_based_cache',
    HAWKS_HIDE_ONLINE_NOW_TOGGLE_FILTER: 'hawks_hide_online_now_toggle_filter',
    HAWKS_ROLES_GIGS_SWITCH_EDUCATION_TOOLTIP: 'hawks_roles_gigs_switch_education_tooltip',
    HIDE_CREATE_BRIEF_MATCH_ENTRY_POINTS_IN_MP: 'hide_create_brief_match_entry_points_in_mp',
    HAWKS_SAVE_PROFESSION_LISTINGS_INITIAL_PROPS_TO_REDIS: 'hawks_save_profession_listings_initial_props_to_redis',
    HAWKS_FIVERR_AI_ENTRY_POINTS: 'hawks_fiverr_ai_entry_points',
    HAWKS_FIVERR_AI_LISTINGS: 'hawks_fiverr_ai_listings',
    HAWKS_PRICE_UPFRONT: 'hawks_price_upfront',

    // Owls
    OWLS_PROFESSION_BASED_CATALOG_LISTINGS_UPDATE_ROLE_CARD: 'profession_based_catalog_listings_update_role_card',

    // TODO Group By Team and cleanup
    CAT_USE_CATALOG_PHOENIX_FOR_SLUGS: 'cat_use_catalog_phoenix_for_slugs',
    BLOCK_CRAWLERS: 'block_crawlers_in_listings',
    LOG_UNKNOWN_CRAWLERS: 'log_unknown_crawlers_in_listings',
    CAT_REPORT_NEW_IMPRESSIONS: 'cat_report_new_impressions',
    PROMOTED_GIGS_BANNER: 'bulls_promoted_gigs_banner_in_listings',
    VIPERS_PROJECT_PARTNER_BANNER: 'vipers_project_partner_banner',
    VIPERS_BRIEF_AND_MATCH_MODAL: 'vipers_brief_and_match_modal',
    CAT_READ_LISTINGS_INITIAL_PROPS_FROM_REDIS: 'cat_read_listings_initial_props_from_redis',
    CAT_SAVE_LISTINGS_INITIAL_PROPS_TO_REDIS: 'cat_save_listings_initial_props_to_redis',
    LIONS_MT_VS_HT_SPLIT: 'lions_mt_vs_ht_split',
    LIONS_DEFAULT_MT_TRANSLATION: 'lions_default_mt_translation',
    CAT_MW_SC_BANNER: 'cat_mw_sc_banner',
    CAT_FETCH_PERSONALIZATION_CONTENT_BY_FUNNEL: 'cat_fetch_personalization_content_by_funnel',
    LIONS_LOCAL_CATALOG_BUYER_SIDE: 'lions_local_catalog_buyer_side',
    CAT_DISPLAY_NAME_ON_GIG_CARD: 'cat_display_name_on_gig_card',
    MUSTANGS_FIB_LISTINGS_MENU_DROPDOWN: 'fib_listings_menu_dropdown',
    CAT_LOCAL_SUBSCRIPTION_TOGGLES_REMOVAL: 'cat_local_subscription_toggles_removal',
    CAT_SUBCATEGORY_BY_SLUG_ROUTE_SWITCH: 'cat_subcategory_by_slug_route_switch',
    CAT_I_WILL_PREFIX_REMOVAL: 'cat_i_will_prefix_removal',
    MUSTANGS_VERIFY_ASSETS: 'mustangs_verify_assets',
    GIG_NEW_DELIVERY_IMAGE_TRANSFORMATION: 'gig_new_delivery_image_transformation',
    CATERPILLARS_ONLINE_PRO_TOGGLE_REMOVAL: 'caterpillars_online_pro_toggle_removal',
    CAT_REMOVE_MIN_PRICE_TOGGLE: 'cat_remove_min_price_toggle',
    CAT_BOOK_CONSULTATION: 'cat_book_consultation',
    MUSTANGS_CUSTOM_DEFAULT_FILTERS: 'mustangs_custom_default_filters',
    PROJECT_PANTHERS_BANNER_FIP_NO_RESULT_PAGE: 'project_panthers_banner_fip_no_result_page',
    HAWKS_LISTINGS_SIDE_FILTERS: 'hawks_listings_side_filters',
    PROMOTED_VIDEO_STRIP: 'bulls_video_strip',
    CARIBOU_RATING_SCORE_UPDATES: 'caribou_rating_score_updates',
    HAWKS_RESPONSIVE_CAROUSELS: 'hawks_responsive_carousels',
    MUSTANGS_PRO_CATALOG_GRID: 'mustangs_pro_catalog_grid',
    MUSTANGS_SHOW_BRIEF_AND_MATCH: 'mustangs_show_brief_and_match',
    MUSTANGS_PRO_DYNAMIC_MATCHING: 'mustangs_pro_dynamic_matching',
    MUSTANGS_MARKETPLACE_DYNAMIC_MATCHING: 'mustangs_marketplace_dynamic_matching',

    // dolphins
    DOLPHINS_HOURLY_RATE_BANNER_LISTINGS_GUEST: 'dolphins_hourly_rate_banner_listings_guest',
    DOLPHINS_HOURLY_RATE_BANNER_LISTINGS_USER: 'dolphins_hourly_rate_banner_listings_user',

    // zebras
    ZEBRAS_HOURLY_RATES: 'zebras_hourly_rate', // show hourly rate banner only
    ZEBRAS_BACK_TO_FIVERR: 'zebras_back_to_fiverr',
    ZEBRAS_MP_HOURLY_FILTER: 'zebras_mp_hourly_filter',
    FALCONS_AGENCIES_IN_LISTINGS_GD: 'falcons_agencies_in_listings_gd',
    DOLPHINS_MP_LISTINGS_PJM_BANNER_DESKTOP: 'dolphins_mp_listings_pjm_banner_desktop',
};
