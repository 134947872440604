import { Domingo } from '@fiverr-private/domingo';
import { getContext } from '@fiverr-private/fiverr_context';
import { EXPERIMENTS, ROLLOUTS } from '@fiverr-private/listing_experiments';
import { FILTER, activeFiltersToQueryString } from '@fiverr-private/listing_lib';
import { buildRedirectQueryParameters, BUSINESS_TRIGGER_CTA } from '@fiverr-private/business_success_sign_modal';
import { LISTINGS_PAGE } from '../../../../../../../constants';
import { EXPERT_LISTINGS_URL_PARAM } from '../../../../../../generic_listings/constants';

export const getProCatalogLinkWithHourlyFilter = ({ source = '' } = {}) => {
    const { url } = getContext();
    const proUrl = new URL(Domingo(url).pro().url);
    if (!proUrl.searchParams.has(EXPERT_LISTINGS_URL_PARAM)) {
        proUrl.searchParams.append(EXPERT_LISTINGS_URL_PARAM, 'true');
    }

    const hasHourlyFilter = { [FILTER.HAS_HOURLY.ID]: 'true' };
    const updatedRef = activeFiltersToQueryString({ ...hasHourlyFilter });

    proUrl.searchParams.delete('ref');
    proUrl.searchParams.append('ref', updatedRef);

    if (source) {
        proUrl.searchParams.delete('source');
        proUrl.searchParams.append('source', source);
    }

    return proUrl.toString();
};

export const onMigrationSuccess = () => {
    const redirectUrl = buildRedirectQueryParameters({
        redirectUrl: getProCatalogLinkWithHourlyFilter(),
        source: LISTINGS_PAGE,
        type: BUSINESS_TRIGGER_CTA.DEFAULT,
    });

    window.open(redirectUrl, '_self');
};

export const shouldShowBannerCondition = ({ rollouts = {} }) => {
    const { experience, abTests } = getContext();
    const inTestGroup = Number(abTests[EXPERIMENTS.ZEBRAS_HOURLY_RATE]) === 2;
    const inZebrasHourlyRatesRollout = rollouts[ROLLOUTS.ZEBRAS_HOURLY_RATES];
    return (!experience.isBusiness || inZebrasHourlyRatesRollout) && !inTestGroup;
};
