import { isEmpty, findIndex } from 'lodash';
import { CurrencyConverter } from '@fiverr-private/futile';
import { getContext } from '@fiverr-private/fiverr_context';
import { getSellerValidatedLanguages } from '@fiverr-private/localization';
import { SellerRepresentative } from '../../constants/seller';
import { getGigBadgeType } from '../utils/gigBadges';
import { PROMOTED_GIGS } from '../utils/gigBadges/constants';
import { GIG_STATUSES } from '../utils/gigStatuses';
import { LIST_VIEW_CARD } from '../utils/constants';
import { shouldDisplayISpeak } from '../../GigCards/utils';
import { isAgencyGig } from '../../utils/agency';
import { transformAssets, transformLocalizedAssets } from './assetsTransformer';
import { transformFeatures } from './featuresTransformer';
import { transformPrice, transformPriceLabel } from './priceTransformer';
import { transformGigUrl, transformSellerUrl, transformAgencyUrl } from './urlTransformer';
import { transformRecurringOptions } from './recurringOptionsTransformer';

/**
 * This transformer acts as API gateway for our package, He is designed to get ListingsPheonix gigs data
 * with context parameters and transform them into our package data structure
 * This layer will prevent context awareness in our components.
 */
export default (gigCardType, props) => {
    const {
        gigs,
        collectProps = {},
        impressionEnrichment = {},
        markUnavailable = false,
        context,
        isLoading,
        isTouch,
        features,
        notableClients = {},
    } = props;

    const listingsContext = {
        gigImpression: {
            listings: impressionEnrichment.listings || {},
            gigData: impressionEnrichment.gigData || {},
            auctionData: impressionEnrichment.auctionData || {},
            pageCtxId: context.pageCtxId,
            viewportType: context.viewportType,
            sourceComponent: context.sourceComponent,
            controllerAction: context.controllerAction,
            pageComponentName: context.pageComponentName,
            pageElementName: context.pageElementName,
            sourcePage: context.sourcePage,
        },
        tracking: {
            pageNum: context.page,
            currentPage: context.currentPage,
            viewportType: context.viewportType,
            subCategory: context.subCategory,
            pageCtxId: context.pageCtxId,
            sourceComponent: context.sourceComponent,
            userId: context.userId,
            currency: context.currency,
        },
    };

    const enrichedCollectProps = {
        ...collectProps,
        sourceInfo: {
            name: context.currentPage,
            userId: context.userId,
            isTouch,
        },
    };

    if (isLoading) {
        return { ...props, ...listingsContext };
    }

    const { currency = {} } = context;
    const currencyConverter = new CurrencyConverter(currency);

    const experimentalFeatures = {
        displayISpeak: props.displayISpeak || false,
        displayCountryFlag: props.displayCountryFlag || false,
    };

    return {
        ...props,
        ...listingsContext,
        experimentalFeatures,
        gigs: gigs.map((gig, index) =>
            transformGig({
                gigCardType,
                gig,
                markUnavailable,
                currencyConverter,
                index,
                context,
                features,
                experimentalFeatures,
            })
        ),
        collectProps: enrichedCollectProps,
        notableClients,
    };
};

/**
 * Transform a single gig into view struct
 * @param gigCardType
 * @param gig
 * @param currencyConverter
 * @param index
 * @param markUnavailable
 * @param context
 * @param features
 * @param experimentalFeatures
 *
 * @returns {{id: number, cacheSlug: (string|*), url: *, type: *, impressionData: (impressionData|{type, algo_type, alg}|{}), assets: *, title: *, price: (String|Number), originalPrice: (number|string), position: *, numOfPackages: number, isPro: *, isFeatured: *, isFiverrChoice: *, isPromotedGig: boolean, showBoughtFor: boolean, showStartingAt: boolean, ratings: {rating, count, original}, badge: {type, excludeTooltip}, categoryId: number, subCategoryId: number, seller: *}}
 */
const transformGig = ({
    gigCardType,
    gig,
    markUnavailable,
    currencyConverter,
    index,
    context,
    features = {},
    experimentalFeatures,
}) => {
    const {
        is_pro: isPro,
        is_seller_unavailable: isSellerUnavailable,
        is_featured: isFeatured,
        status,
        package_i: packageIndex,
        offer_consultation: offerConsultation,
    } = gig;
    const isUnavailable = markUnavailable && (isSellerUnavailable || status === GIG_STATUSES.SUSPENDED);

    const isFiverrChoice = getFiverrChoice(gig, gigCardType);
    const isPromotedGig = getPromotedGig(gig);

    const gigSellerRepresentative = isAgencyGig(gig)
        ? transformGigAgency(gig, context)
        : transformGigSeller(gig, context);

    const { proficientLanguages: sellerProficientLanguages, country: sellerCountry } = gigSellerRepresentative;
    const badge = getGigBadgeType({
        cardType: gigCardType,
        type: gig.type,
        isPro,
        isFeatured,
        isFiverrChoice,
        features,
    });
    const gigUrl = transformGigUrl(gig, index, context, badge);
    const gigImpressionData = withGalleryTagging(gig);

    const { displayISpeak, displayCountryFlag } = experimentalFeatures;
    const { locale, browserLanguage, countryCode } = getContext();

    const showISpeak = shouldDisplayISpeak({
        locale,
        browserLanguage,
        countryCode,
        proficientLanguages: sellerProficientLanguages,
        sellerCountryCode: sellerCountry,
        displayISpeak,
        displayCountryFlag,
    });

    const showCountryFlag = showISpeak || displayCountryFlag;
    const featuresList = transformFeatures(features);
    const { useForcePriceRounding } = featuresList;

    return {
        uId: gig.u_id || gig.gig_id,
        id: gig.gig_id,
        attachmentIds: gig.attachments_ids,
        cacheSlug: gig.cached_slug,
        url: gigUrl,
        type: gig.type,
        gigAttributes: gig.gigAttributes,
        impressionData: gigImpressionData,
        impressionId: gig.impressionId,
        assets: transformAssets({ ...gig, gigImpressionData }),
        localizedAssets: transformLocalizedAssets({ ...gig, gigImpressionData }),
        title: gig.title,
        localizedTitle: gig.localizedTitle,
        translatedTitle: gig.translatedTitle,
        price: transformPrice({ gig, currencyConverter, useForcePriceRounding }),
        numericPricingFactor: gig.numericPricingFactor,
        offerConsultation,
        priceLabelType: transformPriceLabel({ gig }),
        originalPrice: gig.price_i,
        position: index + 1,
        numOfPackages: gig.num_of_packages,
        auction: gig.auction,
        isPro,
        isUnavailable,
        isFeatured,
        isFiverrChoice,
        isPromotedGig,
        isSubscription: gig.has_recurring_option,
        ratings: transformGigRatings(gig),
        badge,
        categoryId: gig.category_id,
        subCategoryId: gig.sub_category_id,
        nestedSubCategoryId: gig.nested_sub_category_id,
        seller: gigSellerRepresentative,
        packageIndex,
        recurringOptions: transformRecurringOptions({ gig }),
        showISpeak,
        showCountryFlag,
        review: gig.review,
        ...featuresList,
    };
};

const transformGigSeller = (gig, context) => {
    const sellerUrl = transformSellerUrl(gig, context);

    // we're turning seller_languages of type [{code:'en', level:3}] into an array of type [{name:'en', level:3}] --- object of type { en: 3 }
    const proficientLanguages = (gig.seller_languages || []).map(({ code, level }) => ({ name: code, level }));
    const sellerLanguages = getSellerValidatedLanguages({ proficientLanguages, countryCode: gig.seller_country });

    return {
        type: SellerRepresentative.FREELANCER,
        id: gig.seller_id,
        level: gig.seller_level,
        name: gig.seller_name,
        displayName: gig.seller_display_name,
        country: gig.seller_country,
        rating: gig.seller_rating,
        url: sellerUrl,
        imgSrc: gig.seller_img,
        isOnline: gig.seller_online,
        proficientLanguages,
        sellerLanguages,
    };
};

const transformGigAgency = (gig, context) => {
    const agencyUrl = transformAgencyUrl(gig, context);
    const { agency } = gig;

    // copied from transformGigSeller
    // we're turning seller_languages of type [{code:'en', level:3}] into an array of type [{name:'en', level:3}] --- object of type { en: 3 }
    const proficientLanguages = (gig.seller_languages || []).map(({ code, level }) => ({ name: code, level }));
    const sellerLanguages = getSellerValidatedLanguages({ proficientLanguages, countryCode: gig.seller_country });

    return {
        type: SellerRepresentative.AGENCY,
        id: gig.seller_id,
        level: gig.seller_level,
        name: gig.seller_name,
        displayName: gig.seller_display_name,
        country: gig.seller_country,
        imgSrc: gig.seller_img,
        isOnline: gig.seller_online,
        rating: gig.seller_rating,
        url: agencyUrl,
        proficientLanguages,
        sellerLanguages,
        employeesCount: agency.employees_count,
        established: agency.established,
        highlights: agency.highlights,
        bannerImageUrl: agency.banner_image_url,
    };
};

const transformGigRatings = ({ buying_review_rating, buying_review_rating_count }) => ({
    rating: buying_review_rating,
    count: buying_review_rating_count,
    original: {
        rating: buying_review_rating,
        count: buying_review_rating_count,
    },
});

const getFiverrChoice = ({ is_fiverr_choice: isFiverrChoice = false }, gigCardType) =>
    isFiverrChoice && gigCardType !== LIST_VIEW_CARD;

const getPromotedGig = ({ type, additional_types = {} } = {}) =>
    type === PROMOTED_GIGS || !!(additional_types && additional_types.promoted_gig);

export const withGalleryTagging = ({ attachments_ids, assets, impressionData = {} }) => {
    const enrichTaggingImpression = (impressionData, tagged, isRelevantDefault) => ({
        ...impressionData,
        gallery_tagging: {
            is_tagged_image: tagged,
            is_relevant_default_image: isRelevantDefault,
        },
    });

    // If no attachments sent via go searcher for relevant asset
    if (isEmpty(attachments_ids)) {
        return enrichTaggingImpression(impressionData, false, false);
    }

    const relevantAssetIndex = findIndex(assets, { id: attachments_ids[0] });

    // If asset doesn't exist in the assets list
    if (relevantAssetIndex === -1) {
        return enrichTaggingImpression(impressionData, false, false);
    }

    const isRelevantDefault = relevantAssetIndex === 0;
    return enrichTaggingImpression(impressionData, true, isRelevantDefault);
};
