import React from 'react';
import classnames from 'classnames';
import { CollectionsProvider } from '@fiverr-private/collect_actions';
import { getContext } from '@fiverr-private/fiverr_context';
import { ComplianceProvider } from '@fiverr-private/compliance';
import { CARD_VARIANT } from '../../shared/constants';
import { LAYOUT_CLASSNAME, LISTINGS_LAYOUT } from '../../constants';
import { ExpertCardSkeleton } from '../../components/ExpertCard/Skeleton';
import ExpertCardListingsContent from '../../components/ExpertCardListingsContent';
import propTypes from './types';

import './index.scss';

/**
 * @typedef ExpertCardListingsProps
 * @property {import('prop-types').InferProps<typeof propTypes>['displayOptions']} displayOptions
 */

/**
 * An entry for Expert Card Listings, includes responsive design, mobile support and impression events
 *
 * @param {Partial<ExpertCardListingsProps>} props
 */
const ExpertCardListings = ({
    items = [],
    currency = {},
    layout = LISTINGS_LAYOUT.LIST,
    containerImpressionEnrichment = {},
    onImpression,
    source,
    cardVariant = CARD_VARIANT.DEFAULT,
    forceSmallCard = false,
    displayOptions = {},
    renderFooterContent,
    banner,
    saveToListProps = {},
    notes,
    inProCatalogGridExp,
    showHourlyPrice = false,
    hourlyRateRolloutActive = false,
    isLoading = false,
    skeletonAmount = 3,
    pageName,
}) => {
    const { userId, organizationId } = getContext();

    return (
        <CollectionsProvider bi={{ source }}>
            <ComplianceProvider requester_id={userId} organizationId={organizationId}>
                <div className={classnames('listings-expert-cards-section', LAYOUT_CLASSNAME[layout])}>
                    {isLoading ? (
                        <div className={classnames('listings-expert-cards')}>
                            {Array.from({ length: skeletonAmount }).map((_, index) => (
                                <ExpertCardSkeleton key={index} isVisual={cardVariant === CARD_VARIANT.VISUAL} />
                            ))}
                        </div>
                    ) : (
                        <ExpertCardListingsContent
                            items={items}
                            currency={currency}
                            containerImpressionEnrichment={containerImpressionEnrichment}
                            onImpression={onImpression}
                            source={source}
                            cardVariant={cardVariant}
                            forceSmallCard={forceSmallCard}
                            displayOptions={displayOptions}
                            renderFooterContent={renderFooterContent}
                            banner={banner}
                            saveToListProps={saveToListProps}
                            notes={notes}
                            inProCatalogGridExp={inProCatalogGridExp}
                            showHourlyPrice={showHourlyPrice}
                            hourlyRateRolloutActive={hourlyRateRolloutActive}
                            pageName={pageName}
                        />
                    )}
                </div>
            </ComplianceProvider>
        </CollectionsProvider>
    );
};

ExpertCardListings.propTypes = propTypes;
ExpertCardListings.LAYOUTS = LISTINGS_LAYOUT;

export default ExpertCardListings;
