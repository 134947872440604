import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Avatar } from '@fiverr-private/fit';
import { enrichGigTracker, trackGigForClickType } from '../../utils';
import GigWrapper from '../../Components/GigWrapper';
import GigRating, { clickedElement } from '../Components/GigRating';
import Price from '../Components/Price';
import SellerSpeaks from '../Components/SellerSpeaks';
import GigTitle from '../Components/GigTitle';
import { GigCardType } from '../../types';
import ContextualLink from '../../Components/ContextualLink';
import CollectWrapper from '../Components/CollectWrapper';
import { getGigRatingProps } from '../utils';
import styles from '../../styles/utils.module.scss';
import CardHeader from './components/CardHeader';
import CardFooter from './components/CardFooter';
import SellerDetails from './components/SellerDetails';
import GigAttributes from './components/GigAttributes';
import ServiceName from './components/ServiceName';
import {
    getSellerDetailsProps,
    getAvatarProps,
    getPriceProps,
    getGigAttributesProps,
    getCollectWrapperProps,
    getGigTitleProps,
    getContextualLinkProps,
    getGigWrapperProps,
    getSellerSpeaksProps,
    getServiceNameProps,
} from './utils';

const SellerFocusedGigCard = (props) => {
    const [enrichedTracker, setEnrichedTracker] = useState({});
    const { trackEvent } = enrichedTracker;

    useEffect(() => {
        setEnrichedTracker(enrichGigTracker(props.tracker, props));
    }, [props]);

    const gigWrapperProps = getGigWrapperProps(props, enrichedTracker);
    const sellerDetailsProps = getSellerDetailsProps(props);
    const collectWrapperProps = getCollectWrapperProps(props);
    const avatarProps = getAvatarProps(props);
    const sellerSpeaksProps = getSellerSpeaksProps(props);
    const gigRatingProps = getGigRatingProps(props);
    const gigAttributesProps = getGigAttributesProps(props);
    const priceProps = getPriceProps(props);
    const contextualLinkProps = getContextualLinkProps(props, trackEvent);
    const titleProps = getGigTitleProps(props);
    const serviceNameProps = getServiceNameProps(props);

    return (
        <GigWrapper {...gigWrapperProps}>
            <ContextualLink {...contextualLinkProps} />
            <CardHeader>
                <Avatar {...avatarProps} />
                <SellerDetails {...sellerDetailsProps} />
                <CollectWrapper {...collectWrapperProps} />
            </CardHeader>
            <ServiceName {...serviceNameProps} />
            <GigTitle {...titleProps} />
            <GigAttributes {...gigAttributesProps} />
            <CardFooter>
                <SellerSpeaks {...sellerSpeaksProps} />
                <ContextualLink
                    {...contextualLinkProps}
                    className={classNames(styles.zIndex1, styles.widthFitContent)}
                    onMouseDown={(e) =>
                        trackGigForClickType(e.button, trackEvent, clickedElement(gigRatingProps.score))
                    }
                >
                    <GigRating {...gigRatingProps} className="m-t-0" />
                </ContextualLink>
                <Price {...priceProps} />
            </CardFooter>
        </GigWrapper>
    );
};

SellerFocusedGigCard.propTypes = GigCardType;

export default SellerFocusedGigCard;
