import React from 'react';
import { func, number, string } from 'prop-types';
import classNames from 'classnames';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { useGigListingsContext } from '../../../../GigCardListings/context/GigListingsContext';
import { trackGigForClickType } from '../../../utils';
import { priceMarkupFormatter } from '../../../../GigCardListings/ListingsTransformer/priceTransformer';
import { clickedElement } from '../../../Components/Price';
import { useCardContext } from '../../../../GigCardListings/context/CardContext';
import ContextualLink from '../../../Components/ContextualLink';

import styles from './index.module.scss';

const Price = ({
    price = 0,
    url,
    urlTarget,
    pricingFactorAlias,
    onMouseEnter,
    onMouseLeave,
    className = '',
    textClassName = '',
}) => {
    const { currencyObj } = useGigListingsContext();
    const {
        enrichedTracker: { trackEvent },
    } = useCardContext();

    return (
        <ContextualLink
            href={url}
            target={urlTarget}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseDown={(e) => trackGigForClickType(e.button, trackEvent, clickedElement(price))}
            className={classNames('m-t-8', 'tbody-5', styles.link, className)}
            ariaLabel={translate('gig_cards.gig_link_aria_label')}
        >
            <span className={classNames('text-bold', 'co-grey-1200', textClassName)}>
                <I18n k="gig_listings.gig_cards.price.from" />
                &nbsp;
                <span dangerouslySetInnerHTML={{ __html: priceMarkupFormatter({ currencyObj, price }) }} />
            </span>

            {pricingFactorAlias && (
                <span className={classNames('co-grey-900', styles.pricingFactor)}>&nbsp;/ {pricingFactorAlias}</span>
            )}
        </ContextualLink>
    );
};

Price.propTypes = {
    price: number,
    url: string,
    urlTarget: string,
    pricingFactorAlias: string,
    onMouseEnter: func,
    onMouseLeave: func,
    className: string,
    textClassName: string,
};

export default Price;
