import React, { useState, useEffect } from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { SingleReview } from '@fiverr-private/reviews';
import { GigCardType } from '../../types';
import { enrichGigTracker } from '../../utils';
import withTransformProps from '../../hoc/withTransformProps';
import { cardTransformer } from '../../utils/transformers';
import GigWrapper from '../../Components/GigWrapper';
import Media from '../../Components/Media';
import GigRating from '../Components/GigRating';
import Price from '../Components/Price';
import { getGigRatingProps } from '../utils';
import { useGigPageLinkHoverState, useMediaProps } from '../BasicGigCard/utils';
import { VIDEO_STRIP_CARD, VIDEO_STRIP_CARD_CLASS } from '../../../GigCardListings/utils/constants';
import LoadingGigVideoStripCard from '../../loading/LoadingGigVideoStripCard';
import { CARD_TYPE } from '../../utils/trackGig';
import CollectWrapper from '../Components/CollectWrapper';
import GigTitle from '../Components/GigTitle';
import SellerInfo from './components/SellerInfo';

import {
    getVideoStripSellerInfoProps,
    getVideoStripCollectWrapperProps,
    getPromotedPriceProps,
    getGigTitleProps,
} from './util';

import './index.scss';

const VideoStripGigCard = (props) => {
    const [enrichedTracker, setEnrichedTracker] = useState({});
    const { uId, isLoading, review, tracker } = props;

    useEffect(() => {
        setEnrichedTracker(enrichGigTracker(tracker, props, CARD_TYPE.VIDEO_STRIP_CARD));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const gigPageLinkHoverState = useGigPageLinkHoverState();
    const mediaProps = useMediaProps(props, gigPageLinkHoverState);
    const collectWrapperProps = getVideoStripCollectWrapperProps(props);
    const sellerInfoProps = getVideoStripSellerInfoProps(props);
    const gigTitleProps = getGigTitleProps(props, gigPageLinkHoverState);
    const ratingProps = getGigRatingProps(props);
    const priceProps = getPromotedPriceProps(props, gigPageLinkHoverState);

    return (
        <GigWrapper
            uId={uId}
            type={VIDEO_STRIP_CARD}
            isLoading={isLoading}
            classes={['video-strip-card']}
            layoutClass={VIDEO_STRIP_CARD_CLASS}
            LoadingComponent={LoadingGigVideoStripCard}
            gig={props}
            enrichedTracker={enrichedTracker}
        >
            <Stack
                gap={{ default: '4', md: '6' }}
                marginY="12"
                height="auto"
                width="100%"
                direction={{ default: 'column', md: 'row' }}
            >
                <Container
                    width="100%"
                    flex={{ default: '0 1 50%', md: '2 1 auto', lg: '0 1 50%' }}
                    height="auto"
                    position="relative"
                >
                    <Media {...mediaProps} isAutoPlay />
                    <CollectWrapper {...collectWrapperProps} />
                </Container>

                <Stack
                    direction="column"
                    width="100%"
                    gap="0"
                    justifyContent="spaceBetween"
                    flex={{ default: '1 1 50%', md: '1 2 auto', lg: '1 1 50%' }}
                >
                    <Stack direction="column" width="100%" gap="0">
                        <SellerInfo {...sellerInfoProps} />
                        <GigTitle {...gigTitleProps} />
                        <GigRating
                            {...ratingProps}
                            scoreClassName="text-display-6"
                            countClassName="responsive-details-text"
                        />
                        <Container marginTop="3">
                            <Price {...priceProps} />
                        </Container>
                    </Stack>
                    {review && (
                        <Container display={{ md: 'none', lg: 'block' }}>
                            <SingleReview review={review} />
                        </Container>
                    )}
                </Stack>
            </Stack>
        </GigWrapper>
    );
};

VideoStripGigCard.propTypes = GigCardType;

export default withTransformProps(cardTransformer)(VideoStripGigCard);
